<script setup lang="ts">
import {FireIcon} from "@heroicons/vue/24/outline";
import {useAuthFetch} from "#imports";
import type {ResponseProject} from "~/interface";
const p1 = computed(() => ({page_size: 3, ordering: '-score'}))
const p2 = computed(() => ({page_size: 4, is_verified: true}))
const p3 = computed(() => ({page_size: 12}))
const [{data: r1}, {data: r2}, {data: r3}] = await Promise.all([
  useAuthFetch<ResponseProject>('/cms/projects/', {
    key: '1',
    params: p1
  }),
  useAuthFetch<ResponseProject>('/cms/projects/', {
    key: '2',
    params: p2
  }),
  useAuthFetch<ResponseProject>('/cms/projects/', {
    key: '3',
    params: p3
  })
])

useHead({
  title: "Telegram Directory"
})

const emoji = (label: string) => {
  if (label === "bot")
    return "🤖"
  if (label === "channel")
    return "📢"
  if (label === "group")
    return "👥"
}
</script>

<template>
  <div class="relative border-b border-gray-100 overflow-hidden">
    <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 mx-auto space-y-6 py-8">
      <div class="">
        <div class="text-3xl md:text-4xl font-bold">Telegram Directory</div>
        <p class="md:text-xl">Browse 59,701 channels, groups and bots.</p>
        <div class="mt-4 flex gap-1 text-xl capitalize font-semibold">
          <nuxt-link
            v-for="label in ['channel', 'group', 'bot']"
            :to="`/type/${label}`"
            class="p-1 bg-gray-100 rounded px-3">{{ emoji(label)}} {{ label }}
          </nuxt-link>
        </div>
      </div>
    </div>
    <div
      class="absolute right-1/2 left-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
      aria-hidden="true">
      <div
        class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
    </div>
  </div>
  <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 mx-auto space-y-6 py-8">
    <div class="space-y-2">
      <div class="md:flex justify-between">
        <div>
          <div class="font-bold flex items-center gap-2 text-xl">
            <FireIcon class="w-5 h-5 text-red-500"/>
            <span>Popular</span>
          </div>
          <p class="text-gray-600">The best projects are selected by members</p>
        </div>
        <div v-if="false" class="text-sm text-blue-500 underline font-semibold">
          <div>View all</div>
        </div>
      </div>
      <div v-if="r1" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 py-2 -mx-2">
        <div v-for="item in r1.results" :key="item.id">
          <card-project :value="item"/>
        </div>
      </div>
    </div>
    <div v-if="r2" class="p-3 bg-gray-50 border rounded-lg">
      <div class="flex justify-between">
        <div>
          <div class="font-bold flex items-center gap-2">
            <FireIcon class="w-5 h-5 text-red-500"/>
            <span>Featured</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 py-2 gap-4">
        <div v-for="item in r2.results" :key="item.id">
          <card-project :value="item"/>
        </div>
      </div>
    </div>
    <div v-if="r3" class="pace-y-2">
      <div class="flex justify-between">
        <div class="font-bold flex items-center gap-2 text-xl">
          <FireIcon class="w-5 h-5 text-red-500"/>
          <span>Newest</span>
        </div>
        <div v-if="false" class="text-sm text-blue-500 underline font-semibold">
          <div>View all</div>
        </div>
      </div>
      <p class="text-gray-600">The best projects are selected by editors</p>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 py-2 gap-4">
        <div v-for="item in r3.results" :key="item.id">
          <card-project :value="item"/>
        </div>
      </div>
    </div>
  </div>
</template>
